import React from "react";
import { graphql } from "gatsby";
import '../style/general-page.scss';
import GeneralPageHeader from "../components/GeneralPage/GeneralPageHeader";
import GeneralContent from "../components/GeneralPage/GeneralContent";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
export default function GeneralPage({ data, isTestPage }) {
  console.log(data)
  const {
    frontmatter: {
      title,
      descriptionHeading,
      imagePosition,
      heading,
      content,
      description,
      contentTitle,
      subtitle,
      heroImage,
    },
  } = data.markdownRemark;


  return (
    <Layout isTestPage={isTestPage}>
      <main>
        <section className="general-page">
          <GeneralPageHeader
            firstHeading={title}
            imagePosition={imagePosition}
            secondHeading={subtitle}
            description={descriptionHeading}
            heroImage={heroImage}
          />
        </section>
        <GeneralContent
          title={contentTitle}
          descriptionText={content}
        />
      </main>
    </Layout>

  );
}


export const pageQuery = graphql`
  query SubPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id

      frontmatter {
        title
        descriptionHeading
        heading
        content
        imagePosition
        description
        content
        contentTitle
        subtitle
        heroImage {
          childImageSharp {
            gatsbyImageData(quality: 100,  placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
    
      }
  }
  }
`;
